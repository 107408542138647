.MsGraphTest .card-header {
  font-weight: bold;
}

.MsGraphTest .mr-05rem {
  margin-right: 0.5rem;
}

.MsGraphTest .mb-1rem {
  margin-bottom: 1rem;
}

.MsGraphTest .overflow-500 {
  max-height: 300px;
  overflow: auto;
  font-size: small;
}

/* Custom */

.MsGraphTest .results-search {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: 1rem;
}
