.TaskFormComp .card-header {
  font-weight: bold;
}

.TaskFormComp .mb-025rem {
  margin-bottom: 0.25rem;
}

.TaskFormComp .mb-015rem {
  margin-bottom: 0.15rem;
}

.TaskFormComp .mb-1rem {
  margin-bottom: 1rem;
}

.TaskFormComp .mb-0rem {
  margin-bottom: 0rem;
}

.TaskFormComp .ml-15rem {
  margin-left: 1.5rem;
}

.TaskFormComp .ml-05rem {
  margin-left: 0.5rem;
}

.TaskFormComp .mt-05rem {
  margin-top: 0.5rem;
}

.TaskFormComp .mt-1rem {
  margin-top: 1rem;
}

.TaskFormComp .mr-05rem {
  margin-right: 0.5rem;
}

.TaskFormComp .p-1rem {
  padding: 1rem;
}

.TaskFormComp .overflow-500 {
  max-height: 300px;
  overflow: auto;
  font-size: small;
}

.TaskFormComp .float-right {
  float: right;
}

.TaskFormComp .slim-hr {
  margin-bottom: 0.5rem;
  margin-top: 0rem;
}

.TaskFormComp .m-1rem {
  margin: 1rem;
}
