.MyTasksComp .card-header {
  font-weight: bold;
}

.MyTasksComp .new-search {
  width: 100%;
  text-align: right;
}

.MyTasksComp .mt-1rem {
  margin-top: 1rem;
}

.MyTasksComp .ml-1rem {
  margin-left: 1rem;
}
