.ItAdmin .card-header {
  font-weight: bold;
}

.ItAdmin .mr-05rem {
  margin-right: 0.5rem;
}

.ItAdmin .mb-1rem {
  margin-bottom: 1rem;
}

.ItAdmin .mt-1rem {
  margin-top: 1rem;
}

.ItAdmin .overflow-500 {
  max-height: 300px;
  overflow: auto;
  font-size: small;
}
