.OrgChartTest .card-header {
  font-weight: bold;
}

.OrgChartTest .mr-05rem {
  margin-right: 0.5rem;
}

.OrgChartTest .mb-1rem {
  margin-bottom: 1rem;
}

.OrgChartTest .overflow-500 {
  max-height: 300px;
  overflow: auto;
  font-size: small;
}

.OrgChartTest .mt-1rem {
  margin-top: 1rem;
}

.OrgChartTest .mb-0rem {
  margin-bottom: 0rem;
}

/* Custom */

.initechNode {
  /* border: solid 3px #007bff;
  border-radius: 3px;
  padding: 5px;
  width: 200px; */
  margin: 0rem 0.25rem 0rem 0.25rem;
  display: inline-block;
}

#initechOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
  border-right: solid 3px #007bff;
}

#initechOrgChart .nodeLineBorderTop {
  border-top: solid 3px #007bff;
}
