.TaskWebReferencesComp .card-header {
  font-weight: bold;
}

.TaskWebReferencesComp .mb-1rem {
  margin-bottom: 1rem;
}

.TaskWebReferencesComp .mb-0rem {
  margin-bottom: 0rem;
}

.TaskWebReferencesComp .mr-05rem {
  margin-right: 0.5rem;
}

.TaskWebReferencesComp .mb-015rem {
  margin-bottom: 0.15rem;
}

.TaskWebReferencesComp .overflow-500 {
  max-height: 300px;
  overflow: auto;
  font-size: small;
}
