.Task .mb-1rem {
  margin-bottom: 1rem;
}

.Task .mb-15rem {
  margin-bottom: 1.5rem;
}

.Task .mb-05rem {
  margin-bottom: 0.5rem;
}

.Task .nav-tabs {
  margin-top: 0.5rem;
}

.Task .nav-item {
  margin-left: 0.5rem;
}

.Task .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.Task .mt-1rem {
  margin-top: 1rem;
}

.Task .overflow-300 {
  max-height: 300px;
  overflow: auto;
  font-size: small;
}

.Task .overflow-500 {
  max-height: 500px;
  overflow: auto;
  font-size: small;
}

.Task .mr-015rem {
  margin-right: 0.15rem;
}

.Task .mb-0rem {
  margin-bottom: 0rem;
}

.Task .mb-015rem {
  margin-bottom: 0.15rem;
}

.Task .ml-05rem {
  margin-left: 0.5rem;
}

.Task .collapse-button {
  float: right;
  padding: 0.25px 15px;
}

.Task .custom-bg-secondary {
  background-color: #6c757d !important;
}

.Task .custom-bg-success {
  background-color: #28a745 !important;
}

.Task .custom-bg-warning {
  background-color: #ffc107 !important;
}

.Task .custom-bg-danger {
  background-color: #dc3545 !important;
}

.Task .custom-bg-success-gradient {
  background-color: rgba(40, 167, 69, 0.5098039215686274) !important;
}
