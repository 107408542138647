body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type="file"] {
  width: 100%;
}
