.User .UserCard {
  margin-bottom: 1rem;
}

.User .nav-tabs {
  margin-top: 0.5rem;
}

.User .nav-item {
  margin-left: 0.5rem;
}

.User .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.User .checkBoxContainer {
  margin-bottom: 1rem;
}

.User .ml-05rem {
  margin-left: 0.5rem;
}

.User .ml-025rem {
  margin-left: 0.25rem;
}

.User .Org {
  margin-bottom: 1rem;
}

.User .Role {
  margin-top: 1rem;
}

.User .Registration {
  margin-top: 1rem;
}

.User .mb-1rem {
  margin-bottom: 1rem;
}

.User .mb-05rem {
  margin-bottom: 0.5rem;
}

.User .mb-0rem {
  margin-bottom: 0rem;
}

.User .hr-margin {
  margin: 0.5rem 0rem 0rem 0rem;
}

.User .mt-05rem {
  margin-top: 0.5rem;
}

.User .mt-1rem {
  margin-top: 1rem;
}

.User .overflow-300 {
  max-height: 300px;
  overflow: auto;
  font-size: small;
}
