.TasksComp .card-header {
  font-weight: bold;
}

.TasksComp .new-search {
  width: 100%;
  text-align: right;
}

.TasksComp .mt-1rem {
  margin-top: 1rem;
}
