.DelegationsReportComp .card-header {
  font-weight: bold;
}

.DelegationsReportComp .mt-1rem {
  margin-top: 1rem;
}

.DelegationsReportComp .mb-0rem {
  margin-bottom: 0rem;
}

.DelegationsReportComp .overflow-500 {
  max-height: 500px;
  overflow: auto;
  font-size: small;
}
