.UserList .card-header {
  font-weight: bold;
}

.Users .NewSearch {
  width: 100%;
  text-align: right;
}

.Users table {
  margin-top: 1rem;
}

.Users .UserCards {
  margin-top: 1rem;
}
