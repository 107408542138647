.SignalRTest .card-header {
  font-weight: bold;
}

.SignalRTest .logCards {
  margin-top: 1rem;
}

.SignalRTest .logCard {
  margin-bottom: 1rem;
  font-size: 0.75rem;
}

.SignalRTest .mb-1rem {
  margin-bottom: 1rem;
}
