.UserCompletionReportComp .card-header {
  font-weight: bold;
}

.UserCompletionReportComp .mt-1rem {
  margin-top: 1rem;
}

.UserCompletionReportComp .mb-0rem {
  margin-bottom: 0rem;
}

.UserCompletionReportComp .overflow-500 {
  max-height: 500px;
  overflow: auto;
  font-size: small;
}

.UserCompletionReportComp .cursor-pointer {
  cursor: pointer;
}

.UserCompletionReportComp .cursor-not-allowed {
  cursor: not-allowed;
}

.UserCompletionReportComp .font-085rem {
  font-size: 0.85rem;
}

.UserCompletionReportComp .table td {
  vertical-align: middle;
}

.UserCompletionReportComp .table thead th {
  vertical-align: middle;
}

.UserCompletionReportComp .legend-dueToday {
  background-color: #dc35458a;
}

.UserCompletionReportComp .legend-late {
  background-color: #28a74582;
}

.UserCompletionReportComp .task-badge {
  cursor: pointer;
  font-size: small;
}

.UserCompletionReportComp .last-update {
  font-size: small;
  float: right;
}
