.SettingsComp .card-header {
  font-weight: bold;
}

.SettingsComp .mr-05rem {
  margin-right: 0.5rem;
}

.SettingsComp .mb-1rem {
  margin-bottom: 1rem;
}

.SettingsComp .mb-0rem {
  margin-bottom: 0rem;
}

.SettingsComp .overflow-500 {
  max-height: 300px;
  overflow: auto;
  font-size: small;
}
